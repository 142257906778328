import logoMFIT from "./../../assets/images/logo-mfit-horizontal-branco 1.png";
import bannerPhones from "./../../assets/images/tela_app_2.png";
import praticidade from "./../../assets/images/praticidade.png";
import tempo from "./../../assets/images/tempo.png";
import arrowUp from "./../../assets/images/Vector4.png";
import dumbbell from "./../../assets/images/Vector3.png";
import peopleIcon from "./../../assets/images/Vector1.png";
import dumbbellIcon from "./../../assets/images/path1057.png";
import videoIcon from "./../../assets/images/Vector2.png";
import xIcon from "./../../assets/images/g3127.png";
import vIcon from "./../../assets/images/path3139.png";
import trainingGif from "./../../assets/images/rotina_treino.gif";
import feedbackGif from "./../../assets/images/feedback.gif";
import walletGif from "./../../assets/images/carteira_mfit.gif";
import evaluationGif from "./../../assets/images/anamnese.gif";
import filesGif from "./../../assets/images/arquivos.gif";
import iconPc from "./../../assets/images/comercio-eletronico 1.png";
import iconBadge from "./../../assets/images/exclusivo.png";
import iconHandShake from "./../../assets/images/aperto de mao.png";
import imgDoublePhones from "./../../assets/images/tela_app_1 2.png";
import aspasImg from "./../../assets/images/Vector.png";
import imgPersonal1 from "./../../assets/images/image 6.png";
import imgPersonal2 from "./../../assets/images/image 7.png";
import imgPersonal3 from "./../../assets/images/image 8.png";
import imgPhoneSolo from "./../../assets/images/tela_app_3 1.png";
import ConversionForm from "./../../components/form/ConversionForm";

const Desktop = () => {

    return (
        <>
            <div className={'bg-custom-gradient pt-20'}>
                <div className={"grid grid-cols-12 text-white"}>
                    <div className={'col-span-12 super-hd:col-start-2 full-hd:col-start-2 italic ps-10 '}>
                        <p className={'text-3xl   super-hd:text-6xl desktop:text-5xl '}>Transforme a tua <br/><span
                            className={'text-5xl super-hd:text-8xl font-bold desktop:text-8xl  '}>Consultoria Online</span>
                        </p>
                    </div>
                    <div
                        className={' col-span-6 super-hd:col-start-2 desktop:pt-20 pt-10 ps-10  super-hd:col-span-4 desktop:col-span-4 text-xl desktop:text-2xl'}>
                        <p className={' italic font-light'}>Gerencie a tua carreira na <span className={'font-bold'}>melhor app para personal trainers</span> do
                            mercado</p>
                        <p className={'  italic font-light pt-6'}>Otimizamos a tua rotina para que possa fidelizar os seus alunos e aumentar os seus rendimentos</p>
                    </div>
                </div>
                <div className={"grid grid-cols-12  text-white -mb-96 super-hd:-mb-72  pt-8 pb-4 super-hd:pb-20 super-hd:pt-12 ps-10 desktop:pt-20"}>
                    <div className={'col-span-6 super-hd:col-start-2  '}>
                        <ConversionForm text={"teste grátis"}/>
                        <p className={'text-success text-sm text-left pt-2 pb-2 italic'}>Teste sem cartão de crédito</p>
                    </div>
                </div>
                <div className={'grid grid-cols-12 pe-10 '}>
                    <div className={'col-span-8  super-hd:col-span-12 super-hd:col-start-8   col-start-6 desktop:-mt-52'}>
                        <div className={'grid grid-cols-12  super-hd:-mt-20'}>
                            <div className={'col-span-12 col-start-8 pb-10 super-hd:col-start-5'}>
                                <img src={logoMFIT} alt={"Logo MFIT Personal"}/>
                            </div>
                            <div className={'col-span-9 super-hd:col-span-8  col-start-3 hero-green-bg desktop:col-start-4'}>
                                <img src={bannerPhones}
                                     alt={"Dois telemóveis mostrando duas telas do app, uma é a tela inicial e a outra da área de materiais exclusivos"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"grid grid-cols-12 gap-4 pt-20 pb-10  items-center"}>
                <div className={'col-start-2 col-span-10  text-center'}>
                    <h2 className={'text-2xl desktop:text-5xl super-hd:text-5xl text-default italic'}>O que eu ganho <span className={'font-bold'}>trabalhando online?</span>
                    </h2>
                </div>
            </div>
            <div className={"grid grid-cols-4 gap-2 justify-items-center ms-10 me-10 text-default super-hd:ms-64 super-hd:me-64"}>
                <div className={'rounded-xl border-2 p-2 col-span-1  '}>
                    <div className={'grid grid-flow-row gap-2  justify-items-center '}>
                        <div className={'pt-4 pb-4'}>
                            <img src={praticidade} style={{"width": "30px"}} alt={"Ícone de telemóvel demonstrando praticidade"}/>
                        </div>
                        <div>
                            <p className={'font-bold text-center'}>Facilite a tua rotina</p>
                        </div>
                        <div className={'pb-3 '}>
                            <p className={'text-sm font-light text-center'}>Na app da MFIT, você trabalha onde e quando quiser, de qualquer lugar do
                                mundo</p>
                        </div>
                    </div>
                </div>
                <div className={'rounded-xl border-2 p-2 col-span-1 '}>
                    <div className={'grid grid-flow-row gap-2  justify-items-center '}>
                        <div className={'pt-8 pb-4'}>
                            <img src={tempo} style={{"width": "40px"}} alt={"Ícone em formato de relógio mostrando otimização de tempo"}/>
                        </div>
                        <div>
                            <p className={'font-bold text-center'}>Otimize o seu tempo</p>
                        </div>
                        <div className={' '}>
                            <p className={'text-sm font-light text-center'}>Não perca mais tempo com deslocações: trabalhe 100% online</p>
                        </div>
                    </div>
                </div>
                <div className={'rounded-xl border-2 p-2 col-span-1  '}>
                    <div className={'grid grid-cols-12 gap-2  justify-items-center '}>
                        <div className={' col-span-12 pt-10 pb-6'}>
                            <img src={arrowUp} style={{"width": "40px"}} alt={"Ícone de seta para cima mostrando aumento de alcance"}/>
                        </div>
                        <div className={"col-span-12 "}>
                            <p className={'font-bold text-center'}>Aumente o seu alcance</p>
                        </div>
                        <div className={' col-span-12 '}>
                            <p className={'text-sm font-light text-center'}>Atenda alunos que moram longe de si com a mesma qualidade do
                                presencial</p>
                        </div>
                    </div>
                </div>
                <div className={'rounded-xl border-2 p-2 col-span-1  '}>
                    <div className={'grid grid-cols-12 gap-2  justify-items-center '}>
                        <div className={' col-span-12 pt-10 pb-6 '}>
                            <img src={dumbbell} style={{"width": "40px"}} alt={"Ícone de haltere mostrando personalização de treinos"}/>
                        </div>
                        <div className={"col-span-12 "}>
                            <p className={'font-bold text-center'}>Personalize os treinos</p>
                        </div>
                        <div className={'col-span-12 '}>
                            <p className={'text-sm font-light text-center'}>Crie treinos personalizados para todos os seus alunos</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"grid grid-cols-12 gap-4 pt-20 pb-20  items-center "}>
                <div className={'  col-start-2 col-span-10 text-center'}>
                    <h2 className={'text-2xl text-default italic super-hd:text-4xl super-hd:pb-8  desktop:text-3xl'}>Não perca esta <span
                        className={'font-bold'}>oportunidade</span></h2>
                </div>
                <div className={'  col-start-2 col-span-10 text-center'}>
                    <ConversionForm text={"inicie na app"}/>
                </div>
                <div className={' col-start-2 col-span-10 text-center'}>
                    <p className={'text-primary text-sm italic font-light'}>Teste gratuitamente por 10 dias, sem cartão de crédito</p>
                </div>
            </div>
            <div className={'grid  grid-cols-3 pt-5  gap-4 ps-4 pe-4 pb-20  super-hd:ms-64 super-hd:me-64'}>
                <div className={" cols-span-12 justify-items-center pt-10 pb-16 border-2 border-primary-lighter rounded-3xl"}>
                    <div className={'grid grid-cols-12'}>
                        <div className={'col-span-4 col-start-6 items-center'}>
                            <img src={peopleIcon} style={{"width": "80px"}} alt={"Ícone com 3 bonequinhos demonstrando 3 pessoas"}/>
                        </div>
                        <div className={' col-span-6 col-start-4  pt-7'}>
                            <p className={'text-3xl text-center'}>Mais de <span
                                className={'font-bold text-4xl text-primary-lighter'}>300 mil personal trainers</span> confiam na app da MFIT</p>
                        </div>
                    </div>
                </div>
                <div className={" cols-span-12 justify-items-center pt-10 pb-16 border-2 border-primary-lighter rounded-3xl"}>
                    <div className={'grid grid-cols-12'}>
                        <div className={'col-span-4 col-start-6 items-center pt-4 pb-4'}>
                            <img src={dumbbellIcon} style={{"width": "80px"}} alt={"Ícone de haltere mostrando personalização de treinos"}/>
                        </div>
                        <div className={' col-span-8 col-start-3  pt-7'}>
                            <p className={'text-3xl text-center'}>Mais de <span
                                className={'font-bold text-4xl text-primary-lighter'}>5 milhões de alunos</span> treinam com a app da MFIT</p>
                        </div>
                    </div>
                </div>
                <div className={" cols-span-12 justify-items-center pt-10 pb-16 border-2 border-primary-lighter rounded-3xl"}>
                    <div className={'grid grid-cols-12'}>
                        <div className={'col-span-4 col-start-6 items-center pt-4 pb-4'}>
                            <img src={videoIcon} style={{"width": "80px"}} alt={"Ícone de vídeo demonstrando material vitual disponível"}/>
                        </div>
                        <div className={' col-span-8 col-start-3  pt-7'}>
                            <p className={'text-3xl text-center'}>Conte com <span
                                className={'font-bold text-4xl text-primary-lighter'}>1800 vídeos</span> demonstrativos de exercícios</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"grid  grid-cols-12 pt-20 pb-10 ps-20 pe-20   super-hd:ms-48 super-hd:me-48"}>
                <div className={'  col-span-6 border-l border-t border-b border-1 border-primary-lighter rounded-l-3xl'}>
                    <div className={"grid ps-2 grid-cols-12 justify-items-center text-primary-lighter"}>
                        <div className={'col-start-2 col-span-10 pt-10 pb-10'}>
                            <p className={'text-4xl text-center super-hd:text-5xl italic'}>Consultoria <span
                                className={'font-bold text-4xl super-hd:text-6xl'}>Presencial</span></p>
                        </div>
                        <div className={'col-span-10 '}>
                            <div className={'grid grid-cols-12'}>
                                <div className={'col-span-10 col-start-2 text-right '}>
                                    <p className={'text-xl'}>Precisa sincronizar horários com os alunos e as tuas rotinas</p>
                                </div>
                                <div className={'col-span-1 self-center  ps-5'}>
                                    <img src={xIcon} alt={"Ícone com X significando que não precisa fazer"}/>
                                </div>
                            </div>
                            <div className={"grid grid-cols-12 pt-4 pb-8"}>
                                <div className={'col-start-2 col-span-11 '}>
                                    <hr className={'border-1 border-primary-lighter'}/>
                                </div>
                            </div>
                        </div>
                        <div className={'col-span-10 '}>
                            <div className={'grid grid-cols-12'}>
                                <div className={'col-span-10 col-start-2 text-right '}>
                                    <p className={'text-xl'}>Tempo perdido com reposição de aulas e cancelamentos</p>
                                </div>
                                <div className={'col-span-1 self-center  ps-5'}>
                                    <img src={xIcon} alt={"Ícone com X significando que não precisa fazer"}/>
                                </div>
                            </div>
                            <div className={"grid grid-cols-12 pt-4 pb-8"}>
                                <div className={'col-start-2 col-span-11 '}>
                                    <hr className={'border-1 border-primary-lighter'}/>
                                </div>
                            </div>
                        </div>
                        <div className={'col-span-10 '}>
                            <div className={'grid grid-cols-12'}>
                                <div className={'col-span-10 col-start-2 text-right '}>
                                    <p className={'text-xl'}>Número de clientes limitado pelo tempo e localização</p>
                                </div>
                                <div className={'col-span-1 self-center  ps-5'}>
                                    <img src={xIcon} alt={"Ícone com X significando que não precisa fazer"}/>
                                </div>
                            </div>
                            <div className={"grid grid-cols-12 pt-4 pb-8"}>
                                <div className={'col-start-2 col-span-11 '}>
                                    <hr className={'border-1 border-primary-lighter'}/>
                                </div>
                            </div>
                        </div>
                        <div className={'col-span-10 '}>
                            <div className={'grid grid-cols-12'}>
                                <div className={'col-span-10 col-start-2 text-right '}>
                                    <p className={'text-xl'}>Só pode atender quem mora perto de si</p>
                                </div>
                                <div className={'col-span-1 self-center  ps-5'}>
                                    <img src={xIcon} alt={"Ícone com X significando que não precisa fazer"}/>
                                </div>
                            </div>
                            <div className={"grid grid-cols-12 pt-4 pb-8"}>
                                <div className={'col-start-2 col-span-11 '}>
                                    {/*<hr className={'border-1 border-primary-lighter'}/>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={' col-span-6  border-t border-b border-1 border-primary-lighter rounded-r-3xl'}>
                    <div className={"grid grid-cols-12 ps-2  justify-items-center bg-gradient-consultoria rounded-3xl text-white"}>
                        <div className={'col-start-2 col-span-10 pt-10 pb-10'}>
                            <p className={'text-4xl text-center super-hd:text-5xl italic'}>Consultoria <br/><span
                                className={'font-bold text-4xl super-hd:text-6xl'}>Online</span></p>
                        </div>
                        <div className={'col-span-10 '}>
                            <div className={'grid grid-cols-12'}>
                                <div className={'col-span-1 self-center col-start-2 '}>
                                    <img src={vIcon} alt={"Ícone com V significando que é uma vantagem"}/>
                                </div>
                                <div className={'col-span-10 '}>
                                    <p className={'text-xl'}>Prescreva treinos quando quiser e o aluno treina quando puder</p>
                                </div>
                            </div>
                            <div className={"grid grid-cols-12 pt-4 pb-8"}>
                                <div className={'col-start-2 col-span-11 '}>
                                    <hr className={'border-1 '}/>
                                </div>
                            </div>
                        </div>
                        <div className={'col-span-10 '}>
                            <div className={'grid grid-cols-12'}>
                                <div className={'col-span-1 self-center col-start-2 '}>
                                    <img src={vIcon} alt={"Ícone com V significando que é uma vantagem"}/>
                                </div>
                                <div className={'col-span-10 '}>
                                    <p className={'text-xl'}>Flexibilidade e liberdade geográfica para atender quantos clientes quiser</p>
                                </div>
                            </div>
                            <div className={"grid grid-cols-12 pt-4 pb-8"}>
                                <div className={'col-start-2 col-span-11 '}>
                                    <hr className={'border-1 '}/>
                                </div>
                            </div>
                        </div>
                        <div className={'col-span-10 '}>
                            <div className={'grid grid-cols-12'}>
                                <div className={'col-span-1 self-center col-start-2 '}>
                                    <img src={vIcon} alt={"Ícone com V significando que é uma vantagem"}/>
                                </div>
                                <div className={'col-span-10 '}>
                                    <p className={'text-xl'}>Não há faltas nem necessidade de reposição</p>
                                </div>
                            </div>
                            <div className={"grid grid-cols-12 pt-4 pb-8"}>
                                <div className={'col-start-2 col-span-11 '}>
                                    <hr className={'border-1 '}/>
                                </div>
                            </div>
                        </div>
                        <div className={'col-span-10 '}>
                            <div className={'grid grid-cols-12'}>
                                <div className={'col-span-1 self-center col-start-2 '}>
                                    <img src={vIcon} alt={"Ícone com V significando que é uma vantagem"}/>
                                </div>
                                <div className={'col-span-10 '}>
                                    <p className={'text-xl'}>Atenda alunos de qualquer lugar totalmente online</p>
                                </div>
                            </div>
                            <div className={"grid grid-cols-12 pt-4 pb-8"}>
                                <div className={'col-start-2 col-span-11 '}>
                                    {/*<hr className={'border-1 '}/>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"grid justify-items-center pt-20 pb-20 text-default "}>
                <div className={'ps-10 pe-10'}>
                    <p className={'text-4xl text-center'}>Veja como gerenciar a tua consultoria da forma <br/><span
                        className={'font-bold'}>mais fácil e rápida</span> na app</p>
                </div>
            </div>
            <div
                className={"grid grid-cols-12 gap-8 justify-items-center pt-20 pb-16 text-default-lighter bg-element-1-left-desktop pe-4 ps-8 super-hd:ms-24 super-hd:me-24"}>
                <div className={'col-span-4 col-start-2 '}>
                    <img src={trainingGif} alt={"Um gif que mostra que é possível prescrever treinos no app"}/>
                </div>
                <div className={'col-span-4  border-l-2 border-default-darker mt-14 mb-10 pb-4 pt-4   super-hd:mt-32'}>
                    <p className={'ps-4 font-bold text-4xl italic'}>
                        Prescreva treinos personalizados em poucos cliques
                    </p>
                    <p className={'ps-4 pt-6 italic text-xl'}>
                        Crie, clone e edite treinos com rapidez e eficiência
                    </p>
                </div>
            </div>
            <div
                className={"grid grid-cols-12 gap-8 justify-items-center pt-20 pb-16 text-default-lighter   pe-4 ps-8 super-hd:ms-24 super-hd:me-24"}>
                <div className={'col-span-4 col-start-4 border-r-2 border-default-darker mt-14 mb-10 pb-4 pt-4 super-hd:mt-32'}>
                    <p className={'pe-4 font-bold text-4xl italic text-right'}>
                        Acompanhe o progresso do aluno
                    </p>
                    <p className={'pe-4 pt-6 italic text-xl text-right'}>
                        Fique de olho na evolução do aluno, deixe comentários e responda aos feedbacks
                    </p>
                </div>
                <div className={'col-span-4  '}>
                    <img src={feedbackGif} alt={"Um gif que mostra que é possível acompanhar o progresso no app"}/>
                </div>
            </div>
            <div
                className={"grid grid-cols-12 gap-8 justify-items-center pt-20 pb-16 text-default-lighter bg-element-1-left-desktop pe-4 ps-8 super-hd:ms-24 super-hd:me-24"}>
                <div className={'col-span-4 col-start-2 '}>
                    <img src={walletGif} alt={"Um gif que mostra que é possível organizar as finanças no app"}/>
                </div>
                <div className={'col-span-4  border-l-2 border-default-darker mt-14 mb-10 pb-4 pt-4   super-hd:mt-32'}>
                    <p className={'ps-4 font-bold text-4xl italic'}>
                        Organize as tuas finanças de uma vez por todas
                    </p>
                    <p className={'ps-4 pt-6 italic text-xl'}>
                        Tenha o controlo das faturas do seu aluno, sabendo o que está em aberto, pago ou vencido
                    </p>
                </div>
            </div>
            <div
                className={"grid grid-cols-12 gap-8 justify-items-center pt-20 pb-16 text-default-lighter   pe-4 ps-8 super-hd:ms-24 super-hd:me-24"}>
                <div className={'col-span-4 col-start-4 border-r-2 border-default-darker mt-14 mb-10 pb-4 pt-4  super-hd:mt-32'}>
                    <p className={'pe-4 font-bold text-4xl italic text-right'}>
                        Prepare avaliações em instantes
                    </p>
                    <p className={'pe-4 pt-6 italic text-right text-xl'}>
                        Veja os detalhes das avaliações físicas e posturais de cada aluno, assim como anamneses e testes neuromotores
                    </p>
                </div>
                <div className={'col-span-4 '}>
                    <img src={evaluationGif} alt={"Um gif que mostra que é possível preparar avaliações no app"}/>
                </div>
            </div>
            <div
                className={"grid grid-cols-12 gap-8 justify-items-center pt-20 pb-16 text-default-lighter bg-element-1-left-desktop pe-4 ps-8 super-hd:ms-24 super-hd:me-24"}>
                <div className={'col-span-4 col-start-2 '}>
                    <img src={filesGif} alt={"Um gif que mostra que é possível partilhar recursos no app"}/>
                </div>
                <div className={'col-span-4  border-l-2 border-default-darker mt-14 mb-10 pb-4 pt-4   super-hd:mt-32'}>
                    <p className={'ps-4 font-bold text-4xl italic'}>
                        Partilhe recursos complementares
                    </p>
                    <p className={'ps-4 pt-6 italic text-xl'}>
                        Adicione arquivos que o seu aluno pode achar útil, como dicas, exames, planners e cardápios
                    </p>
                </div>
            </div>
            <div className={"grid justify-items-center pt-10 pb-20 text-default"}>
                <div className={'ps-6 pe-6'}>
                    <p className={'text-2xl text-center italic'}>Além de tudo isto, <span
                        className={'font-bold'}>na app da MFIT pode:</span></p>
                </div>
            </div>
            <div className={"grid grid-cols-3 ps-10 pe-10 gap-4 justify-items-center ms-10 me-10 text-default pb-24 super-hd:ps-64 super-hd:pe-64"}>
                <div className={'rounded-xl border-2 p-2 col-span-1  '}>
                    <div className={'grid grid-flow-row gap-2  justify-items-center '}>
                        <div className={'pt-4 pb-4'}>
                            <img src={iconPc} className={"px-4"} alt={"Ícone de um computador"}/>
                        </div>
                        <div className={'pb-3 '}>
                            <p className={'text-sm   text-center'}>Criar <span className={'font-bold'}>páginas de vendas personalizadas</span> para
                                atrair mais clientes</p>
                        </div>
                    </div>
                </div>
                <div className={'rounded-xl border-2 p-2 col-span-1  '}>
                    <div className={'grid grid-flow-row gap-2  justify-items-center '}>
                        <div className={'pt-6 pb-4'}>
                            <img src={iconBadge} className={"px-3"} alt={"Ícone que lembra exclusividade"}/>
                        </div>
                        <div className={'pb-3 '}>
                            <p className={'text-sm text-center'}>Acessar <span className={'font-bold'}>materiais exclusivos</span> para evoluir na
                                tua carreira
                            </p>
                        </div>
                    </div>
                </div>
                <div className={'rounded-xl border-2 p-2 col-span-1   '}>
                    <div className={'grid grid-flow-row gap-2  justify-items-center '}>
                        <div className={'pt-6 pb-5'}>
                            <img src={iconHandShake} className={"px-4"} alt={"Ícone simbolizando um aperto de mão"}/>
                        </div>
                        <div className={'pb-3 '}>
                            <p className={'text-sm   text-center'}>Fortalecer a tua <span className={'font-bold'}>credibilidade</span> com a ajuda de
                                um parceiro nutricionista ou fisioterapeuta</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'grid grid-cols-12 super-hd:ps-40 super-hd:pe-40'}>
                <div className={'col-span-10 col-start-2 bg-default-lighter rounded-3xl mt-10 pt-10 '}>
                    <div className={'grid grid-cols-12'}>
                        <div className={'col-span-6 pb-20'}>
                            <div className="grid p-4 gap-2 place-content-center grid-cols-12">
                                <div className={'col-span-8 col-start-3'}>
                                    <p className={'text-4xl text-white italic font-medium   pt-10'}><span
                                        className={'font-bold'}>Teste Grátis</span> sem compromisso</p>
                                </div>
                            </div>
                            <div className="grid p-4 gap-2 place-content-center grid-cols-12">
                                <div className={'col-span-12 col-start-3'}>
                                    <p className={' text-white text-xl pb-5'}>Ainda tem dúvidas se a MFIT é para si?</p>
                                    <p className={' text-white text-xl  pb-10'}>Faça um <span
                                        className={'text-success font-semibold'}>teste grátis por 10 dias</span> sem cadastrar cartão de crédito!</p>
                                </div>
                            </div>
                            <div className="grid grid-flow-row place-content-center ">
                                <ConversionForm text={"junte-se a nós"}/>
                            </div>
                        </div>
                        <div className={'col-span-6 pb-20 -mt-24 bg-element-tablet-right'}>
                            <div className="grid  grid-flow-row place-content-center ">
                                <img src={imgDoublePhones} className={"px-20"} alt={"Foto de dois telemóveis com duas telas do app"}/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="grid p-4 mb-16 pt-20 gap-2 place-content-center grid-flow-row ">
                <div>
                    <p className={'text-3xl text-default italic font-medium  text-center pt-10'}>Personal trainers em todo o mundo estão
                        conquistando <br/>os seus sonhos com a MFIT Personal. <span className={'font-bold'}>Junte-se a eles!</span></p>
                </div>
            </div>
            <div className={"grid grid-cols-3 gap-4 justify-items-center ms-20 me-20 text-default pb-16 super-hd:ps-56 super-hd:pe-56"}>
                <div className={'rounded-xl border-2 p-2 col-span-1   '}>
                    <div className={'grid grid-cols-12 pt-5 pb-5'}>
                        <div className={'col-span-10 col-start-2'}>
                            <div className={'flex items-end justify-between'}>
                                <div>
                                    <img src={aspasImg} style={{"width": "25px"}} alt={"Imagem de umas aspas"}/>
                                </div>
                                <div className={'  justify-self-end'}>
                                    <img src={imgPersonal1} style={{"width": "50px"}} alt={"Foto de um personal trainer homem"}/>
                                </div>
                            </div>
                        </div>
                        <div className={'col-span-10 col-start-2 pt-6'}>
                            <p className={'text-sm'}>Estava a precisar de dar um up no meu atendimento de consultoria, sair do “papelinho”... e a app
                                da MFIT
                                proporcionou-me entregar treinos para os meus clientes com muito mais rapidez e eficiência! Com a MFIT, já atendi mais de
                                170
                                alunos
                                com excelência, elevando muito os meus rendimentos mensais 💪🏼🤩</p>
                            <p className={'font-bold pt-4'}>Pablo Souza</p>
                            <p>Personal Trainer</p>
                        </div>
                    </div>
                </div>
                <div className={'rounded-xl border-2 p-2 col-span-1   '}>
                    <div className={'grid grid-cols-12 pt-5 pb-5'}>
                        <div className={'col-span-10 col-start-2'}>
                            <div className={'flex items-end justify-between'}>
                                <div>
                                    <img src={aspasImg} style={{"width": "25px"}} alt={"Imagem de umas aspas"}/>
                                </div>
                                <div className={'  justify-self-end'}>
                                    <img src={imgPersonal2} style={{"width": "50px"}} alt={"Foto de uma personal trainer mulher"}/>
                                </div>
                            </div>
                        </div>
                        <div className={'col-span-10 col-start-2 pt-6'}>
                            <p className={'text-sm'}>MFIT é a melhor aplicação, a mais completa, simples de usar e de fácil acesso para mim e para
                                as
                                alunas!
                                Vejo benefícios em entregar uma consultoria mais completa e um layout personalizado, aumentando a minha percepção de
                                valor.
                                Também consegui atingir e fidelizar mais alunas online!</p>
                            <p className={'font-bold pt-4'}>Ana Flávia</p>
                            <p>Personal Trainer</p>
                        </div>
                    </div>
                </div>
                <div className={'rounded-xl border-2 p-2 col-span-1   '}>
                    <div className={'grid grid-cols-12 pt-5 pb-5'}>
                        <div className={'col-span-10 col-start-2'}>
                            <div className={'flex items-end justify-between'}>
                                <div>
                                    <img src={aspasImg} style={{"width": "25px"}} alt={"Imagem de umas aspas"}/>
                                </div>
                                <div className={'  justify-self-end'}>
                                    <img src={imgPersonal3} style={{"width": "50px"}} alt={"Foto de um personal trainer homem"}/>
                                </div>
                            </div>
                        </div>
                        <div className={'col-span-10 col-start-2 pt-6'}>
                            <p className={'text-sm'}>Eu trabalhava com folhas de cálculo, mas não era nada funcional. Além de ser mais instrutivo, a app da
                                MFIT ajudou-me a conseguir mais alunos, a mantê-los na consultoria mais tempo, a montar treinos mais rápido e a ter mais controlo
                                financeiro,
                                pois não preciso de cobrar a ninguém. Nem penso em usar outra aplicação!</p>
                            <p className={'font-bold pt-4'}>Lucas Brandão</p>
                            <p>Personal Trainer</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid p-4 mb-16 pt-20 gap-2 place-content-center grid-flow-row ">
                <div>
                    <p className={'text-3xl text-default italic font-medium  text-center pt-10'}>Confira os nossos formatos de <span
                        className={'font-bold'}>assinaturas</span>:</p>
                </div>
            </div>
            <div className={"grid grid-cols-2 gap-4 justify-items-center ms-20 me-20  pb-16 super-hd:ps-56 super-hd:pe-56"}>
                <div className={'rounded-xl border-2 p-2 col-span-1 text-white bg-default  '}>
                    <div className={'grid grid-cols-12 pt-5 pb-5'}>
                        <div className={'col-span-12 text-center'}>
                            <span className={'text-success font-bold text-3xl'}>Grátis</span>
                        </div>
                        <div className={'col-span-12 text-center pt-2 pb-2'}>
                            <span className={'italic text-2xl'}>1 aluno</span>
                        </div>
                        <div className={'col-span-12 text-center'}>
                            <span className={''}>Quando o período de teste grátis de 10 dias expira, pode continuar com um aluno sem cobranças adicionais e seguir usando a nossa plataforma.</span>
                        </div>
                    </div>
                </div>
                <div className={'rounded-xl border-2 p-2 col-span-1   text-white bg-default '}>
                    <div className={'grid grid-cols-12 pt-5 pb-5'}>
                        <div className={'col-span-12 text-center'}>
                            <span className={'text-primary font-bold text-3xl'}>€ 12,00</span>
                        </div>
                        <div className={'col-span-12 text-center pt-2 pb-2'}>
                            <span className={'italic text-2xl'}>Alunos Ilimitados</span>
                        </div>
                        <div className={'col-span-12 text-center'}>
                            <span className={''}>Não importa quanto a tua carreira cresça: por apenas € 12,00 por mês,
                                gerencia cada um dos alunos de forma prática.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'bg-custom-gradient pb-28 mt-20 '}>
                <div className="grid p-4  gap-2  grid-cols-12 pt-24 ">
                    <div className={'col-span-6 super-hd:col-span-6 super-hd:col-start-2'}>
                        <div className="grid p-4  gap-2  grid-cols-12">
                            <div className={"col-span-12 col-start-2 desktop:col-span-8 desktop:col-start-3 full-hd:col-start-1"}>
                                <p className={'text-6xl text-white italic font-semibold  pt-10 full-hd:text-7xl '}>Simplifique a tua carreira hoje mesmo</p>
                            </div>
                            <div className={'col-span-12 col-start-2 desktop:col-span-8 desktop:col-start-3 desktop:pt-10 full-hd:col-start-1'}>
                                <p className={' text-white text-xl font-light desktop:text-2xl full-hd:text-3xl pb-5'}>Descubra como a app da MFIT pode ajudá-lo
                                    a crescer</p>
                            </div>
                            <div className={'col-span-12 full-hd:pt-14 col-start-2 desktop:col-span-8 desktop:col-start-3 desktop:pt-5 full-hd:col-start-1'}>
                                <ConversionForm text={"teste grátis"}/>
                                <p className={'text-success text-sm  pt-2 pb-2 italic'}>Teste gratuitamente por 10 dias, sem cartão de crédito</p>
                            </div>
                        </div>
                    </div>
                    <div className={'col-span-6 super-hd:col-span-5 bg-element-1-right-desktop me-0 pe-0  '}>
                        <img src={imgPhoneSolo} className={"px-20"} alt={"Foto de dois telemóveis com duas telas do app"}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Desktop;
import {useEffect, useState} from "react";
import Button from "./../../components/buttons/Button";
import {Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import 'react-phone-number-input/style.css';
import PhoneInput, {formatPhoneNumberIntl} from 'react-phone-number-input';
import axios from "axios";
import {useParams, useSearchParams} from "react-router-dom";
import TagManager from "react-gtm-module/dist/TagManager";


const ConversionForm = ({text}) => {
    const [show, setShow] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const {mclid, source} = useParams();
    const [searchParams] = useSearchParams();
    const goToApp = () => {
        window.location.href = 'https://www.mfitpersonal.com.br/index?acao=inicio&open_user_tab=true'
    }

    const returnMclid = () => {
        if (searchParams.get("mclid")) {
            return searchParams.get("mclid").replace("%3D", "=");
        } else if (!mclid)
            return btoa("0/null/organic/lpConsultoriaOnlineV2_pt");
        return mclid;
    }

    const initialValues = {
        fullName: '',
        phone: '',
        email: ''
    };

    const validationSchema = Yup.object().shape({
        fullName: Yup.string()
            .required('Nome completo é obrigatório')
            .test('len', 'O nome completo deve ter pelo menos dois nomes', val => val && val.split(' ').length >= 2),
        phone: Yup.string()
            .required('Número de telefone é obrigatório'),
        email: Yup.string()
            .email('Email inválido')
            .required('Email é obrigatório'),
    });

    const handleSubmit = async (values) => {
        setLoading(true)
        const tracker = returnMclid();
        try {
            const response = await axios.post(`https://api.mfitpersonal.com.br/v2/user/?mclid=${tracker}`, values, {
                headers: {
                    'Content-Type': 'application/json',
                },
                timeout: 20000
            })
            if (source === "google") {
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'form_submit',
                        send_to: 'AW-719994153/CkVKCNTe8rkBEKn6qNcC'
                    }
                });
            }
            window.location.href = `https://www.mfitpersonal.com.br/extlogin?ml=${response.data.email}&cd=${response.data.password}`
        } catch (error) {
            if (error.response.status === 406) {
                setShow(1)
            }
        }
        setLoading(false)
    }

    const openModal = () => setIsOpen(true)
    const closeModal = () => setIsOpen(false);
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'visible';
        }
        return () => {
            document.body.style.overflow = 'visible';
        };
    }, [isOpen]);

    return (
        <>
            <Button bgClass={'bg-primary-lighter'} text={text} onClick={openModal}/>
            {isOpen && (
                <>
                    <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex">
                        <div className="relative p-8 bg-default text-white w-full max-w-md m-auto flex-col flex rounded-lg shadow-lg">
                            {
                                show === 0 &&
                                <>
                                    <div className="grid grid-cols-12   pb-8">
                                        <div className={'col-span-11'}>
                                            <p className="text-2xl font-bold  italic ">EXPERIMENTE GRÁTIS</p>
                                        </div>
                                        <div className={'col-span-1 text-end'}>
                                            <button className="modal-close cursor-pointer text-white " onClick={closeModal}>
                                                <span className="text-2xl  ">&times;</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className={'grid grid-cols-12 '}>
                                        <div className={'col-span-12'}>
                                            <Formik
                                                initialValues={initialValues}
                                                validationSchema={validationSchema}
                                                onSubmit={(values) => {
                                                    handleSubmit(values)
                                                }}
                                            >
                                                {({setFieldValue, values}) => (
                                                    <Form>
                                                        <div className="form-group pb-4">
                                                            <label htmlFor="fullName" className="block text-sm font-medium ">Nome Completo</label>
                                                            <Field name="fullName" type="text"
                                                                   className="mt-1 text-default block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-default focus:border-default"/>
                                                            <ErrorMessage name="fullName" component="div" className="text-red-500 text-xs mt-1"/>
                                                        </div>

                                                        <div className="form-group pb-4">
                                                            <label htmlFor="phone" className="block text-sm font-medium  ">Telefone</label>
                                                            <PhoneInput
                                                                defaultCountry="PT"
                                                                value={values.phone}
                                                                onChange={value => setFieldValue('phone', formatPhoneNumberIntl(value))}
                                                                className="mt-1 text-default  w-full px-3 py-2 border  bg-white rounded-md shadow-sm focus:outline-none focus:ring-default focus:border-default"
                                                            />
                                                            <ErrorMessage name="phone" component="div" className="text-red-500 text-xs mt-1"/>
                                                        </div>

                                                        <div className="form-group pb-4">
                                                            <label htmlFor="email" className="block text-sm font-medium ">Email</label>
                                                            <Field name="email" type="email"
                                                                   className="mt-1 text-default block w-full px-3 py-2 border  rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary-lighter"/>
                                                            <ErrorMessage name="email" component="div" className="text-red-500 text-xs mt-1"/>
                                                        </div>
                                                        <div className="form-group  pt-4">
                                                            <button type="submit"
                                                                    className="px-6 w-full  py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-primary hover:bg-primary-lighter focus:outline-none focus:bg-primary-darker focus:ring-primary active:bg-primary-darker"
                                                                    disabled={loading}
                                                            >
                                                                {
                                                                    loading ? "Enviando..." : "Enviar"
                                                                }
                                                            </button>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                </>
                            }
                            {
                                show === 1 &&
                                <>

                                    <div className={'grid grid-cols-12 '}>
                                        <div className={'col-span-12'}>
                                            <p className={'text-2xl text-center pb-10 font-semibold '}>Parece que você já tem uma conta na APP!</p>
                                            <p className={'text-lg text-center'}>Para voltar a utilizar, basta entrar com o seu login e senha!</p>

                                        </div>
                                        <div className={'col-span-12 text-center pt-10'}>
                                            <Button text={"Ir para a app"} onClick={goToApp} bgClass={"bg-primary-lighter"}/>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default ConversionForm;
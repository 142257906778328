import {useEffect} from "react";
import {useParams} from "react-router-dom";
import Desktop from "../components/devices/Desktop";
import {isMobile, isTablet} from "react-device-detect";
import Mobile from "../components/devices/Mobile";
import Tablet from "../components/devices/Tablet";
import TagManager from "react-gtm-module/dist/TagManager";

const Home = () => {
    const {source} = useParams()

    // const initializeMetaPixel = () => {
    //     if (window.fbq) return;
    //
    //     window.fbq = function () {
    //         if (window.fbq.callMethod) {
    //             window.fbq.callMethod.apply(window.fbq, arguments);
    //         } else {
    //             window.fbq.queue.push(arguments);
    //         }
    //     };
    //     window.fbq.queue = [];
    //     window.fbq.loaded = true;
    //     window.fbq.version = '2.0';
    //     window.fbq.callMethod = window.fbq.callMethod || function () {
    //         window.fbq.queue.push(arguments);
    //     };
    //
    //     // Load the Facebook Pixel script
    //     const script = document.createElement('script');
    //     script.async = true;
    //     script.src = 'https://connect.facebook.net/en_US/fbevents.js';
    //     script.onload = () => {
    //         window.fbq('init', '2530843580461022');
    //         window.fbq('track', 'PageView');
    //     };
    //     document.head.appendChild(script);
    //
    // }

    useEffect(() => {
        // if (source === "meta") {
        //     initializeMetaPixel()
        // }
        const tagManagerArgs = {
            gtmId: 'GTM-KFWV49XB'
        };
        TagManager.initialize(tagManagerArgs);
    }, [source]);

    return (
        <>
            {
                isMobile && !isTablet &&
                <Mobile/>
            }
            {
                isMobile && isTablet &&
                <Tablet/>
            }
            {
                !isMobile && !isTablet &&
                <Desktop/>
            }
        </>

    )
}

export default Home;